import isNumber from '@shared/isNumber';

const DEFAULT_DECIMAL_LENGTH = 2;
const DEFAULT_DECIMAL_SEPARATOR = ',';
const DEFAULT_INTEGER_SEPARATOR = ' ';
const MINIMUM_INTEGER_FORMATTED_LENGTH = 4;

type TFormatAmountOptions = {
  decimalLength?: number;
  decimalSeparator?: string;
  isAlwaysDecimal?: boolean;
  numberSeparator?: string;
};

/**
 * Функция форматирования чисел
 * @param num - исходное число
 * @param options - настройки форматирования
 * @param options.decimalLength - количество знаков дробного числа
 * @param options.decimalSeparator - разделитель для дробной части числа
 * @param options.isAlwaysDecimal - oбязательно ли дробное число
 * @param options.numberSeparator - разделитель для целой части числа
 */
const formatAmount = (num: number | string, options: TFormatAmountOptions = {}): string => {
  // если заданное значение не число и не строка
  if (!isNumber(num) && typeof num !== 'string') {
    return '';
  }

  // если заданное значение является строкой, которую мы не можем преобразовать в число
  if (typeof num === 'string' && !isNumber(Number(num))) {
    return '';
  }

  const { decimalLength, decimalSeparator, isAlwaysDecimal, numberSeparator } = {
    decimalLength: DEFAULT_DECIMAL_LENGTH,
    decimalSeparator: DEFAULT_DECIMAL_SEPARATOR,
    isAlwaysDecimal: false,
    numberSeparator: DEFAULT_INTEGER_SEPARATOR,
    ...options
  };
  let [integerPart, decimalPart] = String(num).split('.');

  // форматируем целую часть числа при необходимости
  if (integerPart.length >= MINIMUM_INTEGER_FORMATTED_LENGTH) {
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, `$1${numberSeparator}`);
  }

  // обрезаем дробную часть числа до нужного размера
  if (decimalPart) {
    decimalPart = `${decimalPart}${'0'.repeat(decimalLength)}`.slice(0, decimalLength);
  }

  // если нет дробной части и ее необязательно возвращать
  if (!decimalPart && !isAlwaysDecimal) {
    return integerPart;
  }

  // если нет дробной части, но она обязательна
  if (!decimalPart && isAlwaysDecimal) {
    return `${integerPart}${decimalSeparator}${'0'.repeat(decimalLength)}`;
  }

  return `${integerPart}${decimalSeparator}${decimalPart}`;
};

export default formatAmount;
