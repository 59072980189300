import React from 'react';
import cn from 'classnames';

import ProductListItem from './components/ProductListItem';
import styles from './ProductList.module.scss';

import { TBestProducts } from '@pagesContent/home/entities/bestProducts';

type TProductListProps = {
  className?: string;
  items: TBestProducts;
};

const ProductList = ({ className = '', items }: TProductListProps): JSX.Element => (
  <ul className={cn(styles.component, className)}>
    {items.map(product => (
      <ProductListItem {...product} key={product.productId} />
    ))}
  </ul>
);

export default ProductList;
