import useTranslation from 'next-translate/useTranslation';

import BestProducts from './components/BestProducts';
import styles from './Home.module.scss';

import { TBestProducts } from '@pagesContent/home/entities/bestProducts';

const Home = ({ bestProducts }: { bestProducts: TBestProducts }): JSX.Element | null => {
  const { t } = useTranslation();

  if (bestProducts.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.heading}>{t('home:headingPopularProducts')}</div>
      <BestProducts items={bestProducts} />
    </>
  );
};

export default Home;
