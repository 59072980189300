import cn from 'classnames';
import formatAmount from '@shared/formatAmount';
import styles from './Decimal.module.scss';

type TDecimalProps = {
  className?: string;
  dataTestId?: string;
  /** Количество знаков после запятой */
  decimalRestLength?: number;
  /** Значение, которое нужно отобразить с остатком */
  value: number;
};

const DECIMAL_REST_LENGTH = 2;

const Decimal = ({
  className,
  dataTestId = '',
  decimalRestLength = DECIMAL_REST_LENGTH,
  value
}: TDecimalProps): JSX.Element => {
  const [integerPart, digitalPart] = value.toFixed(decimalRestLength).split('.');

  return (
    <div className={cn(styles.component, className)} data-testid={dataTestId}>
      <span className={styles.value} data-testid={`${dataTestId}:Value`}>
        {formatAmount(integerPart)}
      </span>
      {digitalPart && (
        <>
          ,
          <span className={styles.rest} data-testid={`${dataTestId}:Rest`}>
            {digitalPart}
          </span>
        </>
      )}
    </div>
  );
};

export default Decimal;
