import cn from 'classnames';

import Decimal from '@components/Decimal';
import styles from './Currency.module.scss';

const DECIMAL_REST_LENGTH = 2;

type TCurrencyProps = {
  /** Расположение знака валюты: до числи или после */
  align?: 'left' | 'right';
  className?: string;
  dataTestId?: string;
  /** Количество знаков после запятой */
  decimalRestLength?: number;
  /** Флаг, жирный ли текст */
  isBold?: boolean;
  /** Знак валюты */
  symbol?: string;
  /** Значение, которое нужно отобразить с остатком */
  value: number;
};

const Currency = ({
  align = 'left',
  className,
  dataTestId = '',
  decimalRestLength = DECIMAL_REST_LENGTH,
  isBold = false,
  symbol = '$',
  value
}: TCurrencyProps): JSX.Element => {
  const isNegative = value < 0;

  return (
    <div className={cn(styles.component, isBold && styles.bold, className)} data-testid={dataTestId}>
      {isNegative && '–'}
      {align === 'left' && <span className={styles.leftSymbol}>{symbol}</span>}
      <Decimal decimalRestLength={decimalRestLength} value={Math.abs(value)} />
      {align === 'right' && <span className={styles.rightSymbol}>{symbol}</span>}
    </div>
  );
};

export default Currency;
