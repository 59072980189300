import useTranslation from 'next-translate/useTranslation';
import cn from 'classnames';

import { Link, LinkTheme } from '@components/NextRouteHOC';
import Currency from '@components/Currency';
import Image from 'next/image';
import NoPhotoIcon from '@static/icons/noPhotoBig.svg';
import styles from './ProductListItem.module.scss';

import { TBestProduct } from '@pagesContent/home/entities/bestProducts';
import pageUrls from '@constants/pageUrls';

type TProductListItemProps = TBestProduct & {
  className?: string;
};

const ProductListItem = ({
  className = '',
  imageUrl,
  name,
  number,
  offer: { price },
  urlMakerName
}: TProductListItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Link
      className={cn(styles.component, className)}
      isWithoutVisited
      theme={LinkTheme.DEFAULT}
      to={pageUrls.productDetails(number, urlMakerName)}
    >
      <div className={styles.imageContainer}>
        {imageUrl ? (
          <Image alt={`OEM${name ? ` ${name}` : ''} ${number}`} layout="fill" objectFit="contain" src={imageUrl} />
        ) : (
          <>
            <NoPhotoIcon />
            <span className={styles.textNoPhoto}>{t('common:main.noPhoto')}</span>
          </>
        )}
      </div>
      <Currency className={styles.price} isBold value={price} />
      <div className={styles.description}>{name ? name : `${number}`}</div>
    </Link>
  );
};

export default ProductListItem;
