import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

import { Title, TitleLevel } from 'emex-ui-kit/dist/Title';
import EmexDateIcon from '@static/icons/emexDate.svg';
import CircleWithArrowIcon from '@static/icons/circleWithArrow.svg';
import ContentContainer from '@components/ContentContainer';
import CountryButton from './components/CountryButton';
import QualityControlIcon from '@static/icons/qualityControl.svg';
import styles from './TopBanner.module.scss';

import { BIG_TABLET_WINDOW_WIDTH } from '@constants/breakpoints';
import { EMobileMenuState, prepareMobileMenuParams } from '@constants/mobileMenu';
import { useAppDispatch, useAppState } from '@store/app';
import useWindowSize from 'react-use/lib/useWindowSize';

const TopBanner = (): JSX.Element => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { country } = useAppState();
  const { showModal, toggleCountryModal } = useAppDispatch();
  const currencySymbol = '$';

  const handleCountryButtonClick = () => {
    if (width < BIG_TABLET_WINDOW_WIDTH) {
      const { mobileMenuName, mobileMenuPayload } = prepareMobileMenuParams({
        menuState: EMobileMenuState.COUNTRIES
      });

      showModal(mobileMenuName, mobileMenuPayload);
    } else {
      toggleCountryModal();
    }
  };

  // TODO: factoidCurrencyIcon встречается несколько раз. Возможно нужно создать соответствующий компонент

  return (
    <div className={styles.component}>
      <ContentContainer>
        <Title className={styles.heading} level={TitleLevel.H1}>
          <Trans
            i18nKey="home:headBanner.title"
            values={{ linkText: t(`common:countries.${country}`) }}
            components={{
              CountryButton: <CountryButton onClick={handleCountryButtonClick} />
            }}
          />
        </Title>

        <ul className={styles.factoids}>
          <li className={styles.factoidItem}>
            <QualityControlIcon />
            <span className={styles.factoidText}>
              <Trans i18nKey="home:headBanner.textQualityControl" />
            </span>
          </li>
          <li className={styles.factoidItem}>
            <div className={styles.factoidCurrencyIcon}>
              <CircleWithArrowIcon />
              <span className={styles.factoidCurrency}>{currencySymbol}</span>
            </div>
            <span className={styles.factoidText}>
              <Trans i18nKey="home:headBanner.textFreeReturn" />
            </span>
          </li>
          <li className={styles.factoidItem}>
            <EmexDateIcon />
            <span className={styles.factoidText}>
              <Trans i18nKey="home:headBanner.textCompanyFoundation" />
            </span>
          </li>
        </ul>
      </ContentContainer>
    </div>
  );
};

export default TopBanner;
