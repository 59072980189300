import { ReactNode } from 'react';

import ArrowDownIcon from '@static/icons/arrowDown52.svg';
import styles from './CountryButton.module.scss';

import { MODAL_SELECT_COUNTRY_KEY } from '@constants/modals';

type TCountryButtonProps = {
  children?: ReactNode;
  onClick: () => void;
};

const CountryButton = ({ children, onClick }: TCountryButtonProps) => (
  <button className={styles.component} onClick={onClick} data-modal-trigger={MODAL_SELECT_COUNTRY_KEY}>
    {children}
    <ArrowDownIcon className={styles.icon} />
  </button>
);

export default CountryButton;
