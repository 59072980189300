import { GetServerSideProps, NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';

import Head from '@components/Head';
import HomeContent, { TopBanner } from '@pagesContent/home';
import MainLayout from '@components/layouts/MainLayout';

import { TBestProducts } from '@pagesContent/home/entities/bestProducts';
import fetchBestProducts from '@pagesContent/home/services/fetchBestProducts';

type THomeProps = {
  bestProducts: TBestProducts;
};

const Home: NextPage<THomeProps> = ({ bestProducts }) => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('home:title')}</title>
        <meta name="keywords" content={t('home:metaKeywords')} />
        <meta name="description" content={t('home:metaDescription')} />
      </Head>
      <MainLayout topContent={<TopBanner />}>
        <HomeContent bestProducts={bestProducts} />
      </MainLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  let bestProducts: TBestProducts = [];

  try {
    const { products } = await fetchBestProducts();

    bestProducts = products || [];
  } catch (e) {}

  return {
    props: {
      bestProducts
    }
  };
};

export default Home;
