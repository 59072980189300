import cn from 'classnames';

import MainProduct from './components/MainProduct';
import ProductList from './components/ProductList';
import styles from './BestProducts.module.scss';

import { TBestProducts } from '@pagesContent/home/entities/bestProducts';

type TSuitableProductsListProps = {
  className?: string;
  items: TBestProducts;
};

const BestProducts = ({ className = '', items }: TSuitableProductsListProps): JSX.Element => (
  <div className={cn(styles.component, className)}>
    <MainProduct {...items[0]} />
    <ProductList className={styles.productList} items={items.slice(1)} />
  </div>
);

export default BestProducts;
